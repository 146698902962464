import httpClient from "./http"
import { usePage } from "@inertiajs/vue3"

// track user interactions
const captureUserEvent = (name, properties = {}) => {
  if(!usePage()?.props?.auth?.user?.created_at) {
    return
  }
  return httpClient.post("/mixpanel/track", {
    name,
    properties,
  })
}

export default captureUserEvent
